@use '../../../styles/_global-variables.scss' as global;
@use '../../../styles/_portal-variables.scss' as tresplay;

.slider {
    padding: 75px 0 calc(var(--gapRow) + 250px) 0; //Afegim marge per evitar que es talli amb el mòdul de sota
    margin-top: -75px;
    margin-bottom: -250px;
    overflow: hidden;
    pointer-events: none;
    display: block;
    counter-reset: numeracio_thumbs;
    position: relative;
    z-index: 0;
    & + .slider {
        //Arreglem la separació entre tires precedides per altres tires
        margin-top: -325px;
    }
    &.init {
        :global {
            .swiper {
                background-color: transparent;
            }
        }
    }
    &.webview {
        margin-top: 0;
        padding: 0 0 var(--gapRow) 0;
        margin-bottom: 0;
        &.noTitol {
            margin-top: 0;
        }
    }
    &:hover {
        z-index: 8;
    }
    &.itemresultat {
        padding: 75px 0 var(--gapRow) 0;
        margin-bottom: 0;
        .titol {
            font-weight: normal;
        }
    }
    &.noPadding {
        .infoTitol {
            padding-left: 0;
            padding-right: 0;
        }
        .innerSlider {
            padding-left: 0;
            padding-right: 0;
        }
    }
    :global {
        .swiper-button-disabled {
            display: none;
        }
        .swiper-slide {
            aspect-ratio: 16 / 9;
            padding-bottom: 64px;
            box-sizing: content-box;
        }
        .swiper-slide-visible {
            pointer-events: all;
        }
        .swiper-wrapper {
            min-height: auto;
        }
        @media (hover) {
            .swiper-wrapper {
                min-height: 220px;
            }
            .swiper:hover {
                button:not(.swiper-button-disabled) {
                    display: block;
                }
            }
        }
        .swiper-slide:hover {
            z-index: 2;
        }
        .swiper-slide[data-drets='false'] {
            h2 {
                color: var(--clr_trans_70);
            }
        }
        .bdtv {
            height: 20px;
            bottom: 14px;
        }
        .swiper {
            background-color: var(--clr_trans_90);
            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                width: 56px;
                z-index: 2;
            }
            &:before {
                left: 0;
            }
            &:after {
                right: 0;
            }
        }
    }
    .afegeix {
        position: absolute;
        top: 10px;
        right: 10px;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.2s;
        z-index: 1;
        svg {
            filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.3));
        }
    }
    .overIcon {
        width: 32px;
        height: 32px;
        background-color: rgba(17, 17, 17, 0.5);
        border-radius: 16px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 5px;
        left: 5px;
        z-index: 1;
    }
    .innerOverIcon {
        z-index: 3;
        width: 20px;
        height: 20px;
    }

    &.tiraVideo {
        margin-top: 0px;
        padding-top: 45px;
    }
}

.etiqueta {
    position: absolute;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    top: 8px;
    right: 8px;
    color: var(--clr_trans_100);
    background-color: var(--clr_trans_0);
    padding: 4px 8px;
    font-size: var(--body_xxs);
    text-transform: uppercase;
    border-radius: 4px;
    transition: opacity 0.2s;
    z-index: 1;
}

.icona_play {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.4);
    display: grid;
    place-content: center;
    opacity: 0;
    z-index: 2;
    border-radius: 50%;

    .audio {
        margin-left: 0px;
    }

    &:hover {
        background-color: var(--clr_portal_100);
    }

    // @media #{global.$VP_ALL_MOBILE} {
    //     opacity: 1;
    // }
}

.wrapper:hover {
    .icona_play {
        opacity: 1;
    }
}

.play {
    width: 28px;
    height: 28px;
    opacity: 1;
}

.cRanking .imatgeWrapper {
    &:after {
        content: '';
        opacity: 80%;
        width: 0;
        height: 0;
        border-top: 0px solid transparent;
        border-right: 120px solid #111111;
        border-bottom: 70px solid transparent;
        position: absolute;
        top: 0;
        right: 0;
        pointer-events: none;
        z-index: 1;
    }
    &:before {
        content: counter(numeracio_thumbs);
        counter-increment: numeracio_thumbs;
        font-family: 'Museo Slab 900', 'Roboto Slab', serif;
        position: absolute;
        font-size: 4.3125rem;
        top: -2.5px;
        right: 20px;
        pointer-events: none;
        z-index: 2;
    }
}

.fixSlideWidthPastilla {
    :global {
        .swiper-slide {
            width: auto !important;
        }
    }
    a {
        width: auto;
        display: block;
    }
    a:focus {
        outline: none;
    }
    visibility: hidden;
    .init & {
        visibility: visible;
    }
}

@media #{global.$VP_ALL_MOBILE} {
    .slider .fixSlideWidthPastilla {
        width: 6.1rem;
        height: 6.1rem;
    }
}
@media #{global.$VP_BEYOND_MOBILE} {
    .slider .fixSlideWidthPastilla {
        width: 6.1rem;
        height: 6.1rem;
    }
}
@media #{global.$VP_BEYOND_TP} {
    .slider .fixSlideWidthPastilla {
        width: 6.95rem;
        height: 6.95rem;
    }
}
@media #{global.$VP_EXTRA_LARGE} {
    .slider .fixSlideWidthPastilla {
        width: 8.7rem;
        height: 8.7rem;
    }
}
@media #{global.$VP_SUPER_EXTRA_LARGE} {
    .slider .fixSlideWidthPastilla {
        width: 10rem;
        height: 10rem;
    }
}
@media #{global.$VP_INGENT} {
    .slider .fixSlideWidthPastilla {
        width: 12.07rem;
        height: 12.07rem;
        margin-right: 40px;
    }
}

.cDirecte {
    .capitol {
        opacity: 1;
    }
    .itemHover:hover {
        .capitol {
            order: 2;
        }
        .codiespectador {
            order: 3;
        }
        .progress {
            order: 4;
            margin-top: 8px;
            margin-bottom: 12px;
        }
        .imatgeWrapper {
            margin-bottom: 0;
        }
        .etiqueta {
            opacity: 1;
        }
    }
    .imatgeWrapper:after {
        content: '';
        display: block;
        height: 60px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, rgba(26, 26, 26, 0) 0%, #1a1a1a 100%);
    }
}

.cCercador {
    .cCercadorSwipper {
        padding-left: 0px;
    }
}

.slider .innerSlider {
    color: #fff;
    overflow: visible;
    //max-width: 1328px;
    padding: 0 56px;
    pointer-events: all;

    @media (hover) {
        .itemHover:hover {
            display: flex;
            flex-direction: column-reverse;
            background-color: var(--clr_trans_90);
            z-index: 1;
            .overIcon {
                display: none;
            }
        }
        &.dreta {
            .itemHover:hover {
                margin: -48px -32px -180px 0;
            }
        }
        &.esquerra {
            .itemHover:hover {
                margin: -48px 0 -180px -32px;
            }
        }
        &.poster {
            &.dreta {
                .itemHover:hover {
                    margin: -22px -16px -50px 0;
                }
            }
            &.esquerra {
                .itemHover:hover {
                    margin: -22px 0 -50px -32px;
                }
            }
        }
    }

    &.tiraVideo {
        padding: 0;
    }
}

.slide {
    position: relative;
    z-index: 1;
    @media (hover) {
        pointer-events: none;
    }
}
.wrapper {
    display: block;
    width: 100%;
    transition: all 0.2s;
    position: relative;
    border: 0 solid transparent;
    z-index: 1;
}

.progress:global(.in) {
    display: none;
}

.itemHover {
    @media (hover) {
        &:hover {
            margin: -48px -16px -180px;
            width: calc(
                100% + 32px
            ); //provoca problemes en combinació amb el freemode i el simulatetouch. Mirar si cal o no arreglar-ho.
            position: absolute;
            border: 1px solid var(--clr_trans_80);
            .imatgeWrapper {
                margin-bottom: 14px;
                &::after,
                &::before {
                    opacity: 0;
                    transition: all 0.5s;
                }
            }
            .textWrapper {
                margin-top: 0;
                position: relative;
                padding: 0 16px 16px;
                width: auto;
                z-index: 2;
                display: flex;
                flex-direction: column;
                align-items: baseline;
            }
            .afegeix {
                opacity: 1;
                pointer-events: all;
            }
            .avantitol {
                margin-bottom: 0;
            }
            .titol {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                order: 1;
                margin: 8px 0 4px;
            }
            .capitol {
                opacity: 1;
            }
            .extrainfo {
                order: 2;
                opacity: 1;
                transition-property: opacity;
                transition-delay: 0.2s;
                transition-duration: 0.2s;
            }
            .entradeta {
                opacity: 1;
                order: 4;
                margin: 0 0;
                transition-property: opacity;
                transition-delay: 0.2s;
                transition-duration: 0.2s;
            }
            .etiqueta {
                opacity: 0;
            }
            .despres_fem {
                opacity: 1;
                display: block;
                order: 5;
                margin-bottom: 16px;
                transition-property: opacity;
                transition-delay: 0.2s;
                transition-duration: 0.2s;
            }
            .progress {
                margin: 8px 0 0;
                flex-basis: 100%;
            }
            .progress:global(.veient) {
                display: none;
            }
            .progress:global(.in) {
                display: block;
                margin-bottom: 8px;
            }
            .codi_durada {
                display: flex;
                align-items: center;
                gap: 4px;
                margin-block: 4px 10px;
            }
            .codiespectador {
                display: inline-block;
                margin-right: 8px;
            }
            .durada {
                font-size: var(--body_s);
            }
        }
    }
}
.imatgeWrapper {
    position: relative;
    background: var(--clr_trans_80);
    display: block;
    aspect-ratio: 16 / 9;
    visibility: hidden;
    .init & {
        visibility: visible;
    }
    img {
        transition: opacity 0.5s;
    }
}
.blurWrapper {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: -24%;
    opacity: 0;
    transition: all 0.2s;
}
.imatgeBlurred {
    filter: blur(10px);
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
}

.codi_durada {
    color: var(--clr_trans_10);
}

.durada {
    line-height: 1;
}

.poster {
    :global {
        .swiper-slide {
            aspect-ratio: 288/422;
            padding-bottom: 0;
        }
    }
    .imatgeWrapper {
        aspect-ratio: 288/422;
    }
    .ultimslide {
        aspect-ratio: 288/422;
    }
    .textWrapper {
        opacity: 0;
    }
    .wrapper {
        .textWrapper {
            pointer-events: none;
            position: absolute;
            top: auto;
            left: 0;
            right: 0;
            bottom: 0;
        }
        .titol {
            font-size: var(--headline_xs);
            line-height: 1.3;
            margin-bottom: 8px;
        }
        .entradeta {
            margin: 0 0 5px;
            font-size: var(--body_s);
            line-height: 1.5;
            -webkit-line-clamp: 5;
        }
        .extrainfo {
            margin-bottom: 8px;
        }
        .codiespectador {
            // margin-right: 14px;
            width: 20px;
            height: 20px;
        }
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.9) 78.65%);
            z-index: 1;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.2s;
        }
    }
    .itemHover {
        @media (hover) {
            &:hover {
                margin: -22px -16px -50px;
                &:before {
                    opacity: 1;
                }
                .textWrapper {
                    opacity: 1;
                }
                .imatgeWrapper {
                    margin-bottom: 0;
                }
                .imatge span {
                    display: none;
                }
            }
        }
    }
}
.quadrat {
    :global {
        .swiper-slide {
            aspect-ratio: 1 / 1;
            padding-bottom: 0;
        }
    }
    .imatgeWrapper,
    .ultimslide {
        aspect-ratio: 1 / 1;
        transition: all 0.2s;
        overflow: visible;
    }
    .textWrapper {
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.2s;
        transition-delay: 0.2s;
    }
    @media (hover) {
        .itemHover {
            &:hover {
                width: 160%;
                margin-left: -30%;
                .imatgeWrapper {
                    margin: 0 16% 14px;
                }
                .blurWrapper {
                    opacity: 1;
                    width: calc(148% - 2px);
                    margin-left: 1px;
                }
                .textWrapper {
                    opacity: 1;
                    pointer-events: all;
                    position: relative;
                }
            }
        }
        &.innerSlider.esquerra {
            .itemHover.wrapper:hover {
                margin-left: -60%;
            }
        }
    }
}

.progress {
    width: 100%;
    height: 4px;
    margin-bottom: 16px;
    // margin-top: -8px;
}
.textWrapper {
    position: absolute;
    z-index: 0;
    top: 100%;
    width: 100%;
    margin-top: 8px;
}
.avantitol {
    font-weight: 400;
    text-transform: uppercase;
    font-size: var(--body_xs);
    line-height: 1.2;
    margin-bottom: 8px;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    order: 1;
}
.titol {
    font-size: var(--body_m);
    line-height: 1.3;
    font-weight: 500;
    opacity: 1;
    transition: opacity 0.2s;
    transition-delay: 0.2s;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.capitol {
    font-size: var(--body_m);
    font-weight: 400;
    margin-bottom: 6px;
    opacity: 0;
    line-height: 1.3;
    transition: opacity 0.2s;
}
.extrainfo {
    width: 100%;
    // margin-bottom: 16px;
    opacity: 0;
    transition-property: opacity;
    transition-delay: 0s;
    transition-duration: 0s;
}
.entradeta {
    font-weight: 400;
    font-size: var(--body_s);
    line-height: 1.5;
    opacity: 0;
    transition-property: opacity;
    transition-delay: 0s;
    transition-duration: 0s;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.despres_fem {
    font-size: var(--body_xs);
    opacity: 0;
    transition-property: opacity;
    transition-delay: 0s;
    transition-duration: 0s;
}
.ico_next {
    width: 15px;
    margin-right: 5px;
    vertical-align: top;
}
.ico_clock {
    width: 16px;
    height: 16px;
    padding-right: 0;
}
.despres_info {
    margin-top: 5px;
    display: block;
}
.iconadreta {
    margin-right: 0;
}
.imatge {
    width: 100%;
}
.logocanal {
    position: absolute;
    bottom: 12px;
    left: 12px;
    height: 37px;
    z-index: 1;
}
.logoprograma {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 55%;
    aspect-ratio: 173 / 93;
}
.codiespectador {
    display: none;
    width: 20px;
    height: 20px;
}
.anterior {
    background: rgba(255, 255, 255, 0.8);
    text-align: center;
    color: #000;
    border: none;
    width: 36px;
    height: 36px;
    position: absolute;
    top: calc(50% - 18px);
    left: 0;
    z-index: 2;
    cursor: pointer;
    display: none;
    padding-top: 3px;
}
.seguent {
    background: rgba(255, 255, 255, 0.8);
    text-align: center;
    border: none;
    color: #000;
    width: 36px;
    height: 36px;
    position: absolute;
    top: calc(50% - 18px);
    right: 0;
    z-index: 3;
    cursor: pointer;
    display: none;
    padding-top: 3px;
}

.ultimslide {
    margin-right: 0 !important;
    aspect-ratio: 16/9;
}

.ultim {
    display: block;
    position: absolute;
    top: calc(50% - 18px);

    a {
        color: var(--clr_trans_30);
        width: 36px;
        height: 36px;
        border: 1px solid currentColor;
        display: grid;
        place-items: center;
        font-size: 2.2rem;
        font-weight: 100;
        line-height: 1;

        &:hover {
            color: var(--clr_trans_0);
        }
    }
}

.swiper-wrapper:hover {
    .seguent {
        display: block;
    }
}

.infoTitol {
    font-weight: 300;
    font-size: var(--headline_xs);
    line-height: 1.4;
    text-transform: uppercase;
    padding: 0 0 16px 56px;
    pointer-events: all;
    max-width: 2144px;
    margin: 0 auto;
    a:hover {
        border-bottom: 1px solid;
    }
}
.linktitol {
    margin-bottom: 5px;
    width: 24px;
    height: 24px;
}
.midaLliure {
    :global {
        .swiper-slide {
            aspect-ratio: initial;
            padding-bottom: 0;
        }
    }
}
.filterMosaicWebview {
    padding: 75px 0 calc(var(--gapRow) + 220px) 0;
    :global {
        .swiper-slide {
            padding-bottom: 0px;
        }
    }
}

.cMidaLliure {
    .imatgeWrapper {
        aspect-ratio: 67/17;
    }
    @media (hover) {
        .wrapper {
            &:hover {
                margin: -8px -16px -180px;
                width: calc(100% + 32px);
                position: absolute;
                border: 1px solid var(--clr_trans_80);
                .imatgeWrapper {
                    margin-bottom: 0;
                }
            }
        }
        .innerSlider.esquerra {
            .wrapper:hover {
                margin: -8px 0 -180px -32px;
            }
        }
        .innerSlider.dreta {
            .wrapper:hover {
                margin: -8px -32px -180px 0;
            }
        }
    }
}
.desFoto {
    .imatgeWrapper {
        aspect-ratio: 16/9;
    }
}
.cSeccions {
    padding: 75px 0 calc(var(--gapRow) + 190px) 0;
    @media (hover) {
        .wrapper {
            &:hover {
                margin: -8px -16px -180px;
                width: calc(
                    100% + 32px
                ); //provoca problemes en combinació amb el freemode i el simulatetouch. Mirar si cal o no arreglar-ho.
                position: absolute;
                border: 1px solid var(--clr_trans_80);
                .imatgeWrapper {
                    margin-bottom: 0;
                }
            }
        }
        .innerSlider.esquerra {
            .wrapper:hover {
                margin: -8px 0 -180px -32px;
            }
        }
        .innerSlider.dreta {
            .wrapper:hover {
                margin: -8px -32px -180px 0;
            }
        }
    }
}

.marginBottom {
    margin-bottom: var(--gapRow);
}

.cPastilla {
    :global {
        .swiper-wrapper {
            min-height: auto;
        }
        .swiper-slide {
            padding-bottom: 0;
        }
    }
}

//MEDIA QUERIES
@media #{global.$VP_ALL_MOBILE} {
    .slider .innerSlider {
        padding: 0 16px;
    }
    .infoTitol {
        padding-left: 16px;
    }
    .linktitol {
        margin-bottom: 4px;
    }
    .slider {
        :global {
            .swiper {
                &:before,
                &:after {
                    content: none;
                }
            }
        }
    }
    .etiqueta {
        top: 4px;
        right: 4px;
    }
    .slider .overIcon {
        top: 3px;
        left: 3px;
    }
}

@media #{global.$VP_MOBILE_AND_TP} {
    .cRanking .imatgeWrapper:after {
        font-size: 3rem;
    }
    .ultim {
        top: calc(50% - 14px);
        a {
            width: 28px;
            height: 28px;
            font-size: 1.6rem;
        }
    }
    .cPastilla {
        padding-top: 50px;
    }
    .slider {
        :global {
            .swiper {
                &:before,
                &:after {
                    width: 34px;
                }
            }
        }
    }
    .logocanal {
        height: 32px;
    }
}

@media #{global.$VP_TP} {
    .slider .innerSlider,
    .infoTitol {
        padding: 0 34px;
    }
}

@media #{global.$VP_ALL_MOBILE} {
    .cRanking .imatgeWrapper {
        &:before {
            content: counter(numeracio_thumbs);
            counter-increment: numeracio_thumbs;
            font-family: 'Museo Slab 900', 'Roboto Slab', serif;
            position: absolute;
            font-size: 2.898rem;
            top: 0rem;
            right: 16px;
            pointer-events: none;
            z-index: 2;
        }
        &:after {
            content: '';
            opacity: 80%;
            width: 0;
            height: 0;
            border-top: 0px solid transparent;
            border-right: 90px solid #111111;
            border-bottom: 50px solid transparent;
            position: absolute;
            top: 0;
            right: 0;
            pointer-events: none;
            z-index: 1;
        }
    }

    .cCercador {
        margin-bottom: -210px;
    }
    .logocanal {
        height: 27px;
    }
}

@media #{global.$VP_EXTRA_LARGE} {
    .slider {
        .overIcon {
            &.iconaGran {
                width: 40px;
                height: 40px;
                border-radius: 20px;

                .innerOverIcon {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}

@media #{global.$VP_MASTODONTIC} {
    .slider .innerSlider {
        max-width: 2144px;
    }
    .slider {
        :global {
            .swiper {
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    background: global.$color95;
                    opacity: 0.5;
                    width: calc((100vw - 2032px) / 2);
                    z-index: 2;
                }
                &:before {
                    left: calc(((100vw - 2032px) / -2) + 56px);
                    z-index: 3;
                }
                &:after {
                    left: calc(100% - 56px);
                    z-index: 3;
                }
            }
        }
    }
    .anterior,
    .seguent {
        z-index: 4;
    }
}

@media #{global.$VP_INGENT} {
    .slider .innerSlider {
        padding: 0;
    }
    .infoTitol {
        padding-left: 0;
    }
    .anterior {
        left: calc(((100% - 2144px) / 2) - 45px);
    }
    .seguent {
        right: calc(((100% - 2144px) / 2) - 45px);
    }
    .slider {
        :global {
            .swiper {
                &:before,
                &:after {
                    width: calc((100vw - 2144px) / 2);
                }
                &:before {
                    left: calc((100vw - 2144px) / -2);
                    z-index: 3;
                }
                &:after {
                    left: 100%;
                    z-index: 3;
                }
            }
        }
    }
}

@media (min-width: 1024px) and (max-width: 1200px) {
    .poster {
        .wrapper {
            .titol {
                font-size: var(--body_l);
                margin-bottom: 4px;
                -webkit-line-clamp: 2;
            }
            .entradeta {
                -webkit-line-clamp: 3;
            }
            .extrainfo {
                margin-bottom: 4px;
            }
        }
    }
}

// ESTAS VEIENT MOBILE

.progress:global(.sotaImatge) {
    display: none;
}

@media (max-width: 530px) {
    .mobile_horitzontal {
        :global {
            .swiper-wrapper {
                display: block;
            }
            .swiper-slide {
                padding-bottom: 16px;
                aspect-ratio: auto;
            }
        }
        .durada {
            font-size: var(--body_xs);
        }

        .wrapper {
            display: grid;
            gap: 10px;
            grid-template-columns: 1fr 1fr;
        }
        .textWrapper {
            width: 100%;
            position: relative;
            order: 2;
            margin-top: 0;
            top: 0;
        }
        .imatgeWrapper {
            width: 100%;
        }
        .progress {
            bottom: -12px;
            position: absolute;
            margin: 0;
        }
        .entradeta,
        .progress:global(.veient) {
            display: none;
        }
        .codiespectador {
            margin: 0;
            display: block;
        }
        .progress:global(.sotaImatge) {
            display: block;
        }

        &.webview {
            .extrainfo {
                opacity: 1;
            }
        }
    }
}

.mobile_horitzontal {
    .progress:global(.veient) {
        position: relative;
        bottom: 0;
        margin-bottom: 10px;
    }
}

//FIX SAFARI
@supports not (aspect-ratio: 16 / 9) {
    .slider {
        .imatgeWrapper {
            padding-top: calc(100% / 16 * 9);
        }
        .poster .imatgeWrapper {
            padding-top: calc(100% / 288 * 422);
        }
        .quadrat .imatgeWrapper {
            padding-top: 100%;
        }
        .quadrat .itemHover .imatgeWrapper {
            padding-top: calc(100% / 16 * 11);
        }
    }
}
