@use 'styles/_global-variables.scss' as global;
@use 'styles/_portal-variables.scss' as tresplay;

.logocanal {
    height: 20px;
    pointer-events: none;
    transition: height 0.2s ease-in-out;
}

a:focus .pastilla {
    box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 1);
}

.pastilla {
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    $border: 3px;
    color: #fff;
    background: #000;
    background-clip: padding-box;
    border: solid $border transparent;
    border-radius: 50%;
    text-align: center;
    transition: all 0.2s;
    justify-content: center;
    padding: 5px;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -$border;
        border-radius: inherit;
        background: linear-gradient(to right bottom, #ef7d00, red);
    }

    @media (hover) {
        &:hover {
            margin-top: -10px;
            margin-left: -10px;
            margin-bottom: -20%;
            position: absolute;
            padding: 8px;
        }
        &:active {
            color: #000;
        }
    }
}
.pastilla:hover .logocanal {
    height: 30px;
}
.titol {
    font-family: 'Museo Slab 900', sans-serif;
    text-align: center;
    width: 100%;
    font-weight: bold;
    padding: 0;
}

.actualitat {
    color: #ef7d00;
    &:before {
        background: linear-gradient(to right bottom, #ef7d00, red);
    }
    &.mouseHover {
        color: #111111;
        background: radial-gradient(58.5% 58.5% at 50% 50%, #ef7d00 0%, rgba(239, 125, 0, 0) 100%);
    }
}
.esports {
    color: #ffcc00;
    &:before {
        background: linear-gradient(to right bottom, #ffcc00, #d76768);
    }
    &.mouseHover {
        color: #111111;
        background: radial-gradient(59.5% 59.5% at 50% 50%, #ffcc00 0%, rgba(255, 204, 0, 0) 100%);
    }
}
.arts {
    color: #27b3e8;
    &:before {
        background: linear-gradient(to right bottom, #0fdfb0, #3653f4);
    }
    &.mouseHover {
        color: #111111;
        background: radial-gradient(59.5% 59.5% at 50% 50%, #27b3e8 0%, rgba(39, 179, 232, 0) 100%);
    }
}
.supercampus {
    color: #a172e7;
    &:before {
        background: linear-gradient(to right bottom, #6115c3, #3653f4);
    }
    &.mouseHover {
        color: #ffffff;
        background: radial-gradient(59.5% 59.5% at 50% 50%, #4f10ac 0%, rgba(39, 179, 232, 0) 100%);
    }
}
.cuines {
    color: #c84191;
    &:before {
        background: linear-gradient(to right, #ee851d, #a641ac);
    }
    &.mouseHover {
        color: #111111;
        background: radial-gradient(58% 58% at 50% 50%, #c84191 0%, rgba(200, 65, 145, 0) 100%);
    }
}
.sx3 {
    color: #ef7d00;
    &:before {
        background: linear-gradient(to right, #4f10ac, #4f10ac);
    }
    &.mouseHover {
        color: #111111;
        background: radial-gradient(59.5% 59.5% at 50% 50%, #4f10ac 0%, rgba(79, 16, 172, 0) 100%);
    }
}

.eva {
    color: #b9d13c;
    &:before {
        background: linear-gradient(to right bottom, #ffffff, #d74494);
    }
    &.mouseHover {
        color: #111111;
        background: radial-gradient(59.5% 59.5% at 50% 50%, #ffffff 0%, rgba(185, 209, 60, 0) 100%);
    }
}
.podcasts {
    color: #979797;
    &:before {
        background: linear-gradient(to right, #dbdbdb, #dab936);
    }
    &.mouseHover {
        color: #111111;
        background: radial-gradient(58% 58% at 50% 50%, #979797 0%, rgba(151, 151, 151, 0) 100%);
    }
}
.series,
.colleccions {
    color: #e1261d;
    &:before {
        background: linear-gradient(to right bottom, #f1530a, #cf396f);
    }
    &.mouseHover {
        color: #111111;
        background: radial-gradient(58.5% 58.5% at 50% 50%, #e1261d 0%, rgba(225, 38, 29, 0) 100%);
    }
}
.pelicules {
    color: #e1261d;
    &:before {
        background: linear-gradient(to right bottom, #f1530a, #cf396f);
    }
    &.mouseHover {
        color: #111111;
        background: radial-gradient(58.5% 58.5% at 50% 50%, #e1261d 0%, rgba(225, 38, 29, 0) 100%);
    }
}
.entreteniment {
    color: #e1261d;
    &:before {
        background: linear-gradient(to right bottom, #f1530a, #cf396f);
    }
    &.mouseHover {
        color: #111111;
        background: radial-gradient(58.5% 58.5% at 50% 50%, #e1261d 0%, rgba(225, 38, 29, 0) 100%);
    }
}
.documentals {
    color: #e1261d;
    &:before {
        background: linear-gradient(to right bottom, #f1530a, #cf396f);
    }
    &.mouseHover {
        color: #111111;
        background: radial-gradient(58.5% 58.5% at 50% 50%, #e1261d 0%, rgba(225, 38, 29, 0) 100%);
    }
}

@media #{global.$VP_ALL_MOBILE} {
    .pastilla {
        $width: 6.5rem;
        $height: 6.5rem;
        width: $width;
        height: $height;
        font-size: var(--body_xxs);
        @media (hover) {
            &:hover {
                font-size: 15px;
                width: calc($width + 20px);
                height: calc($height + 20px);
            }
        }
    }
}
@media #{global.$VP_BEYOND_MOBILE} {
    .pastilla {
        $width: 6.25rem;
        $height: 6.25rem;
        width: $width;
        height: $height;
        font-size: var(--body_xxs);
        @media (hover) {
            &:hover {
                font-size: var(--body_xs);
                width: calc($width + 20px);
                height: calc($height + 20px);
            }
        }
    }
}
@media #{global.$VP_BEYOND_TP} {
    .pastilla {
        $width: 7.18rem;
        $height: 7.18rem;
        width: $width;
        height: $height;
        font-size: var(--body_xs);
        @media (hover) {
            &:hover {
                font-size: var(--body_s);
                width: calc($width + 20px);
                height: calc($height + 20px);
            }
        }
    }
}
@media #{global.$VP_EXTRA_LARGE} {
    .pastilla {
        $width: 8.75rem;
        $height: 8.75rem;
        width: $width;
        height: $height;
        font-size: var(--body_m);
        @media (hover) {
            &:hover {
                font-size: var(--body_l);
                width: calc($width + 20px);
                height: calc($height + 20px);
            }
        }
    }
}
@media #{global.$VP_SUPER_EXTRA_LARGE} {
    .pastilla {
        $width: 10.5rem;
        $height: 10.5rem;
        width: $width;
        height: $height;
        font-size: var(--body_l);
        @media (hover) {
            &:hover {
                font-size: var(--headline_xs);
                width: calc($width + 20px);
                height: calc($height + 20px);
            }
        }
    }
}
@media #{global.$VP_INGENT} {
    .pastilla {
        $width: 12.4rem;
        $height: 12.4rem;
        width: $width;
        height: $height;
        font-size: var(--headline_xs);
        @media (hover) {
            &:hover {
                font-size: var(--headline_s);
                width: calc($width + 20px);
                height: calc($height + 20px);
            }
        }
    }
}
